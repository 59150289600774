import { useTheme } from "@mui/styles"

import React, { 
    useContext, 
    useReducer, 
    useState,
    useEffect,
    forwardRef
} from "react"

import { 
    LoadingContext, 
    SizeContext, 
    UserContext 
} from "../../../context/handleContext"

import axios from "axios"
import { api } from "../../../utils/defaults"
import { toast } from "react-toastify"

import { 
    Box,
    Button, 
    Checkbox, 
    FormControl, 
    FormControlLabel, 
    IconButton, 
    InputLabel, 
    MenuItem, 
    Modal, 
    Popover, 
    Popper, 
    Select, 
    Switch, 
    TextField,
    Typography
} from "@mui/material"

import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import CategoryIcon from '@mui/icons-material/Category';
import DoneIcon from '@mui/icons-material/Done';

import CloseIcon from '@mui/icons-material/Close';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import Table from '../../table/virtualized'
import { useDropzone } from "react-dropzone"
import { ChromePicker } from "react-color"

const Editor = forwardRef((props, ref) => {
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [open, setOpen] = useState(props.open)
    const [list, setList] = useState(props.data || [])
    const [state, setState] = useState({open:false})
    const [fundo, setFundo] = useState({})
    const [logo, setLogo] = useState({})
    const [anchor, setAnchor] = useState({})
    const [font, setFont] = useState([])

    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const theme = useTheme()

    const update = (idtabelaprecopreferencia=undefined) => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/price/preferences${idtabelaprecopreferencia?`?idtabelaprecopreferencia=${idtabelaprecopreferencia}`:''}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            if (idtabelaprecopreferencia) setState({open: true, ...response.data})
            else props.setData(response.data)
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const handleClick = (event,component) => setAnchor({...anchor, default: event.currentTarget, component});

    useEffect(() => setList(props.data) , [ props.data ])

    useEffect(() => {
        if ( !list?.length ) update ()
        else setLoading(true)

        axios
        .get(`${api}/api/manage/price/font`, {
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setFont(data))
        .catch(({response}) => toast.error(response.data))
        .finally(() => {
            if (list?.length) setLoading(false)
        })
    }, [])

    const dropBg = useDropzone({
        maxSize: 3145728,
        onDropAccepted: e => {
            const reader = new FileReader();

            reader.onloadend = () => {
                const form = new FormData()
                form.append('imagem', e[0])

                axios
                .post(`${api}/api/manage/price/preferences/background/${state.idtabelaprecopreferencia}`,form,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response => {
                    toast.success('Salvo')
                    setFundo({
                        file: e[0],
                        url: reader.result
                    })
                })
                .catch(err => toast.error(`Erro ao enviar fundo: ${err.response.data}`))
                .finally(() => setLoading(false))
            };
            setLoading(true)
            reader.readAsDataURL(e[0])
        }
    })

    const dropLogo = useDropzone({
        maxSize: 3145728,
        onDropAccepted: e => {
            const reader = new FileReader();

            reader.onloadend = () => {
                const form = new FormData()
                form.append('imagem', e[0])

                axios
                .post(`${api}/api/manage/price/preferences/logo/${state.idtabelaprecopreferencia}`,form,{
                    headers: {
                        Authorization: user.token
                    }
                })
                .then(response => {
                    toast.success('Salvo')
                    setLogo({
                        file: e[0],
                        url: reader.result
                    })
                })
                .catch(err => toast.error(`Erro ao enviar logo: ${err.response.data}`))
                .finally(() => setLoading(false))
                setLoading(false)
            };
            setLoading(true)
            reader.readAsDataURL(e[0])
        }
    })

    const removeImage = (image) => {
        setLoading(true)
        axios
        .delete(`${api}/api/manage/price/preferences/${image}/${state.idtabelaprecopreferencia}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            if (image === 'logo') {
                setLogo({})
                setState({...state, logo: undefined, tipo_logo: undefined})
            } else {
                setFundo({})
                setState({...state, fundo: undefined, tipo_fundo: undefined})
            }
            toast.success('Removido')
        })
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }

    React.useImperativeHandle(ref, () => ({ 
        open: idtabelaprecopreferencia => update(idtabelaprecopreferencia)
    }))

    const save = () => {
        if (state.idtabelaprecopreferencia) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/price/preferences`,state, {
                headers: {
                    Authorization: user.token
                }
            })
            .then(({data}) => {
                update()
                toast.success('Sucesso')
            })
            .catch(({response}) => toast.error(response.data))
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/price/preferences`,state, {
                headers: {
                    Authorization: user.token
                }
            })
            .then(({data}) => {
                update(data.idtabelaprecopreferencia)
                update()
                toast.success('Sucesso')
            })
            .catch(({response}) => toast.error(response.data))
            .finally(() => setLoading(false))
        }
    }

    const remove = (idtabelaprecopreferencia) => {
        let id = toast(
            <div
            style={{
                // backgroundColor:theme.palette.mode==='dark'?'#272727':'#eee',
                padding:'20px',
                borderRadius:'10px'
            }}>
                <Typography 
                sx={{color:'text.primary',fontSize:'18px',marginBottom:'10px'}}>Deseja realmente excluir ?</Typography>
                <Button onClick={() => {
                    toast.dismiss(id)
                    setLoading(true)
                    axios
                    .delete(`${api}/api/manage/price/preferences/${idtabelaprecopreferencia}`,{
                        headers: {
                            Authorization: user.token
                        }
                    })
                    .then(({data}) => {
                        update()
                        toast.success('Sucesso')
                    })
                    .catch(({response}) => toast.error(response.data))
                    .finally(() => setLoading(false))
                }} variant='contained' color='success' sx={{marginRight:'10px'}}>Sim</Button>
                <Button onClick={() => toast.dismiss(id)} variant='contained' color='error'>Não</Button>
            </div>
            ,{
                autoClose: false,
                closeButton: false,
                closeOnClick: false,
                draggable: false
        })
    }

    return (
        <>
            {props.showButton &&
            <Button
            startIcon={<FormatColorFillIcon />}
            color='primary'
            variant='contained'
            onClick={() => setOpen(true)}
            // sx={{marginRight:'10px'}}
            size='small'
            >
                Temas
            </Button>}

            <Modal
            open={state.open}
            onClose={() => setState({open: false})}
            sx={{
                display:'flex',
                justifyContent:'center',
                width: '100vw',
                height: '100vh',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    height:'fit-content'
                }}
                >
                    <Popover
                        open={anchor.default!==null && anchor.default}
                        anchorEl={anchor.default}
                        onClose={() => setAnchor({...anchor, default: null})}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        sx={{zIndex:9981}}
                    >
                        <ChromePicker
                        onChange={e => setState({...state, preferencias: {
                            ...state.preferencias,
                            [anchor.component]: e.hex
                        }})}
                        color={state?.preferencias ? state?.preferencias[anchor.component] : '#000'}
                         />
                    </Popover>

                    <Popper 
                    open={anchor.color!==null && anchor.color} 
                    anchorEl={anchor.color}
                    sx={{zIndex:9980}}
                    >
                        <Box 
                        sx={{ 
                            border: 1, 
                            p: 1, 
                            bgcolor: 'background.paper',
                            color:'#fff',
                            display:'flex',
                            flexDirection:"column",
                            justifyContent:'left'
                        }}>
                        <div onClick={e => handleClick(e,'primaryColor')} style={{display:'flex',alignItems:'center',cursor:'pointer',margin:'10px'}}>
                            <div style={{height:'15px',width:'20px',borderRadius:'2px',backgroundColor:state?.preferencias?.primaryColor,marginRight:'10px'}}></div>
                            <Typography>Cor Primária</Typography>
                        </div>
                        <div onClick={e => handleClick(e,'secondaryColor')} style={{display:'flex',alignItems:'center',cursor:'pointer',margin:'10px'}}>
                        <div style={{height:'15px',width:'20px',borderRadius:'2px',backgroundColor:state?.preferencias?.secondaryColor,marginRight:'10px'}}></div>
                            <Typography>Cor Secundária</Typography>
                        </div>
                        <div onClick={e => handleClick(e,'fontColor')} style={{display:'flex',alignItems:'center',cursor:'pointer',margin:'10px'}}>
                        <div style={{height:'15px',width:'20px',borderRadius:'2px',backgroundColor:state?.preferencias?.fontColor,marginRight:'10px'}}></div>
                            <Typography>Fonte</Typography>
                        </div>
                        <div onClick={e => handleClick(e,'offerColor')} style={{display:'flex',alignItems:'center',cursor:'pointer',margin:'10px'}}>
                            <div style={{height:'15px',width:'20px',borderRadius:'2px',backgroundColor:state?.preferencias?.offerColor,marginRight:'10px'}}></div>
                            <Typography>Oferta</Typography>
                        </div>
                        <div onClick={e => handleClick(e,'fontQueue')} style={{display:'flex',alignItems:'center',cursor:'pointer',margin:'10px'}}>
                            <div style={{height:'15px',width:'20px',borderRadius:'2px',backgroundColor:state?.preferencias?.fontQueue,marginRight:'10px'}}></div>
                            <Typography>Fila</Typography>
                        </div>
                        <div onClick={e => handleClick(e,'qrContainerColor')} style={{display:'flex',alignItems:'center',cursor:'pointer',margin:'10px'}}>
                            <div style={{height:'15px',width:'20px',borderRadius:'2px',backgroundColor:state?.preferencias?.qrContainerColor,marginRight:'10px'}}></div>
                            <Typography>Container QR</Typography>
                        </div>
                        <div onClick={e => handleClick(e,'qrBackgroundColor')} style={{display:'flex',alignItems:'center',cursor:'pointer',margin:'10px'}}>
                            <div style={{height:'15px',width:'20px',borderRadius:'2px',backgroundColor:state?.preferencias?.qrBackgroundColor ? state?.preferencias?.qrBackgroundColor : '#fff',marginRight:'10px'}}></div>
                            <Typography>Fundo QR</Typography>
                        </div>
                        <div onClick={e => handleClick(e,'qrForegroundColor')} style={{display:'flex',alignItems:'center',cursor:'pointer',margin:'10px'}}>
                            <div style={{height:'15px',width:'20px',borderRadius:'2px',backgroundColor:state?.preferencias?.qrForegroundColor ? state?.preferencias?.qrForegroundColor : '#000',marginRight:'10px'}}></div>
                            <Typography>Fora QR</Typography>
                        </div>
                        <div onClick={e => handleClick(e,'qrTextColor')} style={{display:'flex',alignItems:'center',cursor:'pointer',margin:'10px'}}>
                            <div style={{height:'15px',width:'20px',borderRadius:'2px',backgroundColor:state?.preferencias?.qrTextColor ? state?.preferencias?.qrTextColor:'#fff',marginRight:'10px'}}></div>
                            <Typography>Texto QR</Typography>
                        </div>
                        </Box>
                    </Popper>

                    <Popper 
                    open={anchor.function!==null && anchor.function} 
                    anchorEl={anchor.function}
                    sx={{zIndex:9981}}>
                        <Box 
                        sx={{ 
                            border: 1, 
                            p: 1, 
                            bgcolor: 'background.paper',
                            color:'#fff',
                            display:'flex',
                            flexDirection:"column",
                            justifyContent:'left'
                        }}>
                            <FormControlLabel control={
                                <Switch 
                                checked={state?.preferencias?.table} 
                                onChange={({target}) => setState({
                                    ...state,
                                    preferencias: {
                                        ...state.preferencias,
                                        table:target.checked
                                    }
                                })} />
                            } label="Tabela" />

                            <FormControlLabel control={
                                <Switch 
                                checked={state?.preferencias?.marketing} 
                                onChange={({target}) => setState({
                                    ...state,
                                    preferencias: {
                                        ...state.preferencias,
                                        marketing:target.checked
                                    }
                                })} />
                            } label="Marketing" />
                            <FormControlLabel control={
                                <Switch 
                                checked={state?.preferencias?.header} 
                                onChange={({target}) => setState({
                                    ...state,
                                    preferencias: {
                                        ...state.preferencias,
                                        header:target.checked
                                    }
                                })} />
                            } label="Cabeçalho" />
                            <FormControlLabel control={
                                <Switch 
                                checked={state?.preferencias?.queue} 
                                onChange={({target}) => setState({
                                    ...state,
                                    preferencias: {
                                        ...state.preferencias,
                                        queue:target.checked
                                    }
                                })} />
                            } label="Fila" />
                            <FormControlLabel control={
                                <Switch 
                                checked={state?.preferencias?.price} 
                                onChange={({target}) => setState({
                                    ...state,
                                    preferencias: {
                                        ...state.preferencias,
                                        price:target.checked
                                    }
                                })} />
                            } label="Consulta preço" />
                        </Box>
                    </Popper>

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between'
                    }}
                    >
                        <div>
                            <Button
                            variant='outlined'
                            color='success'
                            startIcon={<DoneIcon />}
                            onClick={save}
                            >salvar</Button>

                            {size.width > 500 &&
                            <TextField 
                            sx={{ width:'250px', marginLeft:'10px'}}
                            label='Nome'
                            size="small"
                            value={state.nomepreferencia}
                            onChange={({target}) => setState({...state, nomepreferencia: target.value})}
                            />}
                        </div>

                        <Button
                        variant='contained'
                        color='error'
                        onClick={() => setState({open: false})}
                        startIcon={<CloseIcon />}
                        >fechar</Button>
                    </div>

                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        marginTop:'10px'
                    }}>
                        <div>
                            <div
                            style={{
                                width:'100%',
                                display:'flex',
                                justifyContent:'center',
                                marginBottom:'20px'
                            }}
                            >
                                <Button 
                                onClick={({currentTarget}) => setAnchor({...anchor, function: (anchor.function ? null : currentTarget)})}
                                sx={{marginRight:'5px'}}>Ativar funções</Button>
                                <Button 
                                onClick={({currentTarget}) => setAnchor({...anchor, color: (anchor.color ? null : currentTarget)})}
                                sx={{marginLeft:'5px'}}>alterar Cores</Button>
                            </div>
                            {size.width <= 500 &&
                            <TextField 
                            sx={{ width:'100%', marginBottom:'10px'}}
                            label='Nome'
                            size="small"
                            value={state.nomepreferencia}
                            onChange={({target}) => setState({...state, nomepreferencia: target.value})}
                            />}
                            <div
                            style={{
                                width:'100%',
                                marginBottom:'10px'
                            }}
                            >
                                <FormControl sx={{width:'calc(50% - 5px)', marginRight:'5px'}}>
                                    <InputLabel>Fonte</InputLabel>
                                    <Select
                                    size="small"
                                    value={state.preferencias?.fontFamily}
                                    onChange={({target}) => setState({
                                        ...state,
                                        preferencias: {
                                            ...state.preferencias,
                                            fontFamily: target.value
                                        }
                                    })}
                                    sx={{fontFamily: state.preferencias?.fontFamily}}
                                    >
                                        {font.map(e => (<MenuItem sx={{fontFamily: e}} value={e}>{e}</MenuItem>))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{width:'calc(50% - 5px)', marginLeft:'5px'}}>
                                    <InputLabel>Fonte Tabela</InputLabel>
                                    <Select
                                    size="small"
                                    value={state.preferencias?.fontFamilyTable}
                                    onChange={({target}) => setState({
                                        ...state,
                                        preferencias: {
                                            ...state.preferencias,
                                            fontFamilyTable: target.value
                                        }
                                    })}
                                    sx={{fontFamily: state.preferencias?.fontFamilyTable}}
                                    >
                                        {font.map(e => (<MenuItem sx={{fontFamily: e}} value={e}>{e}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </div>

                            <TextField 
                            size="small"
                            label='Texo QR code'
                            fullWidth 
                            value={state.preferencias?.qrText}
                            onChange={({target}) => setState({
                                ...state,
                                preferencias: {
                                    ...state.preferencias,
                                    qrText: target.value
                                }
                            })}
                            sx={{marginBottom:'10px'}}
                            />

                            <TextField 
                            size="small"
                            label='Conteúdo QR code'
                            fullWidth 
                            value={state.preferencias?.qr}
                            onChange={({target}) => setState({
                                ...state,
                                preferencias: {
                                    ...state.preferencias,
                                    qr: target.value
                                }
                            })}
                            sx={{marginBottom:'10px'}}
                            />

                            <TextField 
                            size="small"
                            label='Video YouTube'
                            fullWidth 
                            value={state.preferencias?.video}
                            onChange={({target}) => setState({
                                ...state,
                                preferencias: {
                                    ...state.preferencias,
                                    video: target.value
                                }
                            })}
                            sx={{marginBottom:'10px'}}
                            />
                        </div>
                        
                    </div>

                    <div
                    style={{
                        minWidth:'200px',
                        height:'100%',
                        display:'flex'
                    }}
                    >
                        <div
                        style={{
                            width:'calc(50% - 5px)',
                            marginRight:'5px',
                            marginTop:'10px',
                            opacity: 1//table.idtabelapreco ? 1 : 0.5
                        }}
                        >
                            <section className="container">
                                <div 
                                {...dropBg.getRootProps({className: 'dropzone'})}
                                // style={{position:'relative'}}
                                >
                                    {(fundo.url || state.tipo_fundo) &&
                                    <div
                                    style={{
                                        justifyContent:'right',
                                        display:'flex',
                                        position:'absolute',
                                        zIndex:99
                                    }}>
                                        <IconButton
                                        onClick={() => removeImage('background')}>
                                            <CloseIcon 
                                            color='error' 
                                            sx={{
                                                boxShadow:'0px 0px 10px 0px black'
                                            }} />
                                        </IconButton>
                                    </div>}
                                    {(fundo.url || state.tipo_fundo) ?
                                    <img 
                                    style={{
                                        objectFit:'contain',
                                        width:'100%',
                                        maxWidth:'350px'
                                    }}
                                    src={fundo.url || `data:${state.tipo_fundo};base64, ${state.fundo}`}
                                    /> :
                                    <div
                                    style={{
                                        height:'266px',
                                        border: '5px dotted black',
                                        borderRadius:'10px',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        flexDirection:'column'
                                    }}>
                                        <AddPhotoAlternateIcon sx={{fontSize:'100px', color:'black'}} />
                                        <Typography
                                        sx={{
                                            textAlign:'center',
                                            color: 'text.primary'
                                        }}
                                        >Plano de Fundo</Typography>
                                    </div>}
                                    {<input {...dropBg.getInputProps()} />}
                                    {<Typography
                                    sx={{
                                        textAlign:'center',
                                        color: 'text.primary'
                                    }}
                                    >Clique ou arraste o arquivo</Typography>}
                                </div>
                            </section>
                        </div>
                        <div
                        style={{
                            width:'calc(50% - 5px)',
                            marginLeft:'5px',
                            marginTop:'10px',
                            opacity: 1//table.idtabelapreco ? 1 : 0.5
                        }}
                        >
                            <section className="container">
                                <div 
                                {...dropLogo.getRootProps({className: 'dropzone'})}
                                // style={{position:'relative'}}
                                >
                                    {(logo.url || state.tipo_fundo) &&
                                    <div
                                    style={{
                                        justifyContent:'right',
                                        display:'flex',
                                        position:'absolute',
                                        zIndex:99
                                    }}>
                                        <IconButton
                                        onClick={() => removeImage('logo')}>
                                            <CloseIcon 
                                            color='error' 
                                            sx={{
                                                boxShadow:'0px 0px 10px 0px black'
                                            }} />
                                        </IconButton>
                                    </div>}
                                    {(logo.url || state.tipo_logo) ?
                                    <img 
                                    style={{
                                        objectFit:'contain',
                                        width:'100%',
                                        maxWidth:'350px'
                                    }}
                                    src={logo.url || `data:${state.tipo_logo};base64, ${state.logo}`}
                                    /> :
                                    <div
                                    style={{
                                        height:'266px',
                                        border: '5px dotted black',
                                        borderRadius:'10px',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        flexDirection:'column'
                                    }}>
                                        <AddPhotoAlternateIcon sx={{fontSize:'100px', color:'black'}} />
                                        <Typography
                                        sx={{
                                            textAlign:'center',
                                            color: 'text.primary'
                                        }}
                                        >Logo</Typography>
                                    </div>}
                                    {<input {...dropLogo.getInputProps()} />}
                                    {<Typography
                                    sx={{
                                        textAlign:'center',
                                        color: 'text.primary'
                                    }}
                                    >Clique ou arraste o arquivo</Typography>}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                display:'flex',
                justifyContent:'center',
                width: '100vw',
                height: '100vh',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    height:'fit-content',
                    margin:'10px'
                }}
                >
                    <div
                    style={{
                        width:'100%',
                        margin: '0px 0px 10px 0px',
                        display:'flex',
                        justifyContent:'space-between'
                    }}
                    >
                        <div
                        style={{
                            display:'flex'
                        }}
                        >
                            <Button
                            startIcon={<AddIcon />}
                            color='success'
                            variant='outlined'
                            onClick={() => setState({open: true})}
                            sx={{ marginRight:'10px' }}
                            size='small'
                            >
                                Adicionar
                            </Button>

                            <Button
                            startIcon={<SyncIcon />}
                            color='primary'
                            variant='outlined'
                            onClick={() => update()}
                            sx={{marginRight:'10px'}}
                            size='small'
                            >
                                Atualizar
                            </Button>
                        </div>

                        <Button
                        startIcon={<CloseIcon />}
                        color='error'
                        variant='contained'
                        onClick={() => setOpen(false)}
                        // sx={{marginRight:'10px'}}
                        size='small'
                        >
                            fechar
                        </Button>

                        {/* <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={company.map((e) => ({...e, label: e.nome}))}
                        sx={{ 
                            width: size.width > 745 ? 300 : '100%',
                            marginBottom: size.width < 745 && '10px',
                            marginRight:'10px'
                        }}
                        size='small'
                        value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                        onChange={(event, value) => setSelectedCompany(value || false)}
                        renderInput={(params) => <TextField {...params} label="Empresa" />}
                        disabled={!user.all_store}
                        /> */}
                    </div>

                    <Table 
                    list={list}
                    header={[
                        {
                            "field": "idtabelaprecopreferencia",
                            "headerName": "ID",
                            flex: 1
                        },{
                            "field": "nomepreferencia",
                            "headerName": "Nome",
                            flex: 1
                        }, {
                            "field": "",
                            "headerName": "Excluir",
                            renderCell: (params) => {
                                return (
                                    <IconButton
                                    color='error'
                                    onClick={() => remove(params.row.idtabelaprecopreferencia)}
                                    >
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                )
                            }
                        }
                    ]}
                    rowsPerPage={5}
                    pageSizeOptions={[5, 10, 20]}
                    check={false}
                    page={0}
                    onCheck={console.log}
                    onRowClick={(data, event) => {
                        if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
                        update(data.row.idtabelaprecopreferencia)
                    }}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={size.width > 650 ? true : false}
                    sx={{
                        height:'100%'
                    }}
                    // height='calc(100vh - 200px)'
                    />
                </div>
            </Modal>
        </>
    )
})

export default Editor