// import 'tui-image-editor/dist/tui-image-editor.css';
// import ImageEditor from '@toast-ui/react-image-editor';
import { useEffect, useRef, useState, useReducer, useContext } from 'react';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from 'axios';
import { api } from '../../utils/defaults';
import { toast } from 'react-hot-toast';
import { Button, ButtonBase, IconButton, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ImageUploading from 'react-images-uploading';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Close } from '@mui/icons-material';
import { LoadingContext, SizeContext, UserContext } from '../../context/handleContext';


const imageEditorOptions = {
    // sort of option properties.
};

const myTheme = {
    // Theme object to extends default dark theme.
  };

export default function Index (props) {
    const [state, setState] = useState(props.state)
    const [image, setImage] = useState({})
    const [images, setImages] = useState([])
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [changed, setChanged] = useState({})
    const [edited, setEdited] = useState()
    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)

    const editorRef = useRef()
    const theme = useTheme()
    const maxNumber = 5;

    const onCrop = () => {
        const cropper = editorRef.current?.cropper;
        setEdited(cropper.getCroppedCanvas().toDataURL());
    };

    useEffect(() => {
        setState(props.state)
    },[props.state])

    useEffect(() => {
        if (!state) return
        
        setLoading(true)
        axios
        .get(`${api}/api/manage/product/image?ean=${state.ean ? state.ean : ''}&internal=${state.imageminterna?'true':'false'}${state.idvariacaoproduto ? `&idvariacaoproduto=${state.idvariacaoproduto}` : `&idproduto=${state.idproduto}`}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(async response => {
            const a = []
            for (let i in response.data) {
                let e = response.data[i]
                const base64 = await fetch(`data:${e.type};base64, ${e.file}`);
                const blob = await base64.blob();
                const file = new File([blob], `${e.name}.${e.ext}`,{
                    type: e.type
                })
                
                a.push({
                    data_url: `data:${e.type};base64, ${e.file}`,
                    file
                })
            }
            setImages(a)
            if (a.length) setChanged({
                index: 0,
                image: a[0]
            })
        })
        .catch(err => {
            toast.dismiss()
            toast.error(err.response.data)
            if (state.imageminterna) props.close()
        })
        .finally(() => setLoading(false))
    },[state])

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList)
    };

    const saveEdit = async () => {
        const base64 = await fetch(edited);
        const blob = await base64.blob();
        const type = base64.headers.get('content-type')
        const ext = changed.ext || (['jpeg', 'jpg'].indexOf(type.split('/')[1]) !== -1 ? 'jpg' : type.split('/')[1])

        const file = new File([blob], `${changed.name || `fromurl_${Date.now()}`}.${ext}`,{
            type: base64.headers.get('content-type')
        })
        
        const i = images

        if (changed.url) {
            if (images.length === maxNumber) return
            i.push({
                data_url: edited,
                file
            })
            setChanged({})
        } else {
            for (let j in images) {
                if (parseInt(j) === parseInt(changed.index)) {
                    i[j] = {
                        data_url: edited,
                        file
                    }
                    break
                }
            }
        }
        
        setImages(i)
        forceUpdate()
    }

    const save = () => {
        const form = new FormData()
        images.map((e, index) => form.append(`image_${index}`, e.file))

        // return
        if (state.idvariacaoproduto) {
            form.append('idvariacaoproduto', state.idvariacaoproduto)
        } else {
            form.append('idproduto', state.idproduto)
        }

        const t = toast.loading('Salvando...')

        axios
        .post(`${api}/api/manage/product/image`,form,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: user.token
            }
        })
        .then(response => toast.success('Sucesso'))
        .catch(err => toast.error(err.response.data))
        .finally(() => toast.dismiss(t))
    }

    return (
        <div
        style={{
            minWidth:'390px'
        }}
        >
            <div
            style={{
                padding:'20px',
                borderRadius:'10px',
                backgroundColor:theme.palette.mode === 'dark' ? '#121212' : '#fff',
                // maxHeight:size.width <= 500 ? '90vh' : '600px',
                maxHeight: '90vh',
                overflowY:'auto'
            }}>
                <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                >
                    {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    }) => (
                    // write your building UI
                    <>
                    <div 
                    className="upload__image-wrapper"
                    {...dragProps}
                    style={{
                        display:'flex',
                        flexDirection: size.width >= 510 ? 'row' : 'column'
                    }}>
                        <div
                        style={{
                            display:'flex',
                            flexDirection:size.width >= 510 ? 'column' : 'row',
                            marginRight:size.width >= 510 ? '20px' : '0px',
                            maxHeight:'max-content'
                        }}>
                            {imageList.map((image, index) => (
                            <ButtonBase
                            onClick={({target}) => {
                                if (['svg','path'].indexOf(target.localName) !== -1) return
                                setChanged({
                                    index,
                                    image
                                })
                            }}
                            >
                                <div 
                                key={index} 
                                className="image-item"
                                style={{
                                    borderRadius:'10px',
                                    margin: size.width >= 510 ? '5px 0px' : '0px 5px'
                                }}
                                >
                                    {!state.imageminterna && 
                                    <div
                                    style={{
                                        display:'flex',
                                        justifyContent:'right',
                                        width:'100%',
                                        position:'absolute',
                                        top:'-5px',
                                        right:'-10px'
                                    }}>
                                        <div
                                        style={{
                                            borderRadius:'10px',
                                            boxShadow:'0px 0px 5px 1px rgba(0,0,0,0.5)',
                                            display:'flex',
                                            justifyContent:'center',
                                            alignItems:'center'
                                        }}>
                                            <DeleteIcon color='error'
                                            onClick={() => {
                                                onImageRemove(index)
                                                setChanged({})
                                            }} />
                                        </div>
                                    </div>}
                                    <img 
                                    src={image.data_url} 
                                    alt="" 
                                    width="70" 
                                    style={{
                                        borderRadius:'10px',
                                        border: image.imageminterna && `0.3px solid ${theme.palette.mode === 'dark' ? '#fff' : '#000'}`
                                    }}/>
                                    <div className="image-item__btn-wrapper">
                                    {/* <button onClick={() => onImageUpdate(index)}>Update</button>
                                    <button onClick={() => onImageRemove(index)}>Remove</button> */}
                                    </div>
                                </div>
                            </ButtonBase>
                            ))}
                            {images.length < maxNumber && !state.imageminterna &&
                            <ButtonBase
                            onClick={(event) => {
                                onImageUpload(event)
                                setChanged({})
                            }}
                            >
                                <div 
                                className="image-item"
                                style={{
                                    borderRadius:'10px',
                                    width:'70px',
                                    height:'70px',
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:"center"
                                }}
                                >
                                    <AddIcon color='primary' />
                                </div>
                            </ButtonBase>}
                        </div>
                        <div
                        style={{
                            // justifyContent:"center",
                            alignItems:'center',
                            display:'flex',
                            flexDirection:'column',
                            with:'100%'
                        }}>
                            {((changed.image && !isDragging) || changed.url) && !state.imageminterna &&
                            <>
                            <Cropper
                            src={changed.url || changed.image.data_url}
                            style={{ height: 'max-content', maxWidth:'350px' }}
                            // Cropper.js options
                            // initialAspectRatio={16 / 9}
                            guides={false}
                            crop={onCrop}
                            ref={editorRef}
                            />
                            <div
                            style={{
                                display:'flex',
                                justifyContent:'space-between',
                                width:'100%'
                            }}>
                                <IconButton color='error' onClick={() => {
                                    setChanged({})
                                    setImage()
                                }}>
                                    <CloseIcon />
                                </IconButton>
                                <IconButton onClick={saveEdit}>
                                    <CheckIcon color='success' />
                                </IconButton>
                            </div>
                            </>
                            }

                            {state.imageminterna && 
                            <div
                            style={{
                                height:'100%',
                                display:'flex',
                                justifyTracks:'center',
                                alignItems:'center'
                            }}>
                                <img src={changed.image?.data_url}></img>
                            </div>
                            }

                            {(isDragging || !changed.image) && !changed.url && !state.imageminterna &&
                            <div
                            style={{
                                display:'flex',
                                justifyContent: 'center',
                                alignItems:'center',
                                border: `5px dotted black`,
                                width:'350px',
                                height:'350px',
                                borderRadius:'10px',
                                cursor:'pointer'
                            }}
                            onClick={onImageUpload}
                            >
                                <CloudUploadIcon sx={{fontSize:'100px'}} />
                            </div>}

                            {!state.imageminterna &&
                            <TextField
                            value={changed.url || ''}
                            onChange={({target}) => setChanged({...changed, url: target.value})}
                            label='URL da imagem'
                            fullWidth
                            sx={{marginTop:'10px'}}
                            />}
                        </div>
                    </div>
                    {!state.imageminterna &&
                    <div
                    style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        marginTop:'10px'
                    }}>
                        <Button onClick={onImageRemoveAll} variant='outlined'>remover tudo</Button>
                        <Button onClick={props.close} color='error' variant='outlined'>cancelar</Button>
                        <Button onClick={save} color='success' variant='outlined'>salvar</Button>
                    </div>}
                    </>
                    )}
                </ImageUploading>
            </div>
        {/* } */}
        </div>
    )
}