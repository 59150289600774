import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { CompanyContext, LoadingContext, SelectedCompanyContext, SizeContext, UserContext } from "../../../context/handleContext"
import { toast } from "react-toastify"

import { 
    Button,
    ButtonGroup,
    MenuItem,
    Modal,
    Select,
    Typography,
    useTheme,
    Autocomplete,
    TextField,
    FormControlLabel,
    Switch
} from "@mui/material";

import SettingsIcon from '@mui/icons-material/Settings';

import Confirm from "../../../utils/confirm";

import ListAltIcon from '@mui/icons-material/ListAlt';
import Table from '../../table/virtualized'
import Editable from "../../table/editable";

import SyncIcon from '@mui/icons-material/Sync';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';

import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import FactCheckIcon from '@mui/icons-material/FactCheck';

import {
    DataGrid,
    GridEditSingleSelectCell,
    useGridApiContext,
    GridActionsCellItem
  } from '@mui/x-data-grid';
import axios from "axios";
import { api } from "../../../utils/defaults";

const vlrvenda = (state) => {
    let promocao = Number.isNaN(state.promocao) ? undefined : parseFloat(state.promocao)
    let desconto = Number.isNaN(state.desconto) ? undefined : parseFloat(state.desconto)
    let porcdesconto = Number.isNaN(state.porcdesconto) ? undefined : parseInt(state.porcdesconto)

    if (promocao) return promocao

    let vlr = parseFloat(state.valor)
    vlr = vlr - (desconto || 0)

    if (desconto) return vlr

    vlr = vlr - (porcdesconto ? vlr*porcdesconto*0.01 : 0)
    
    return Number.isNaN(vlr) ? 0 : vlr
}

function CustomTypeEditComponent(props) {
    const apiRef = useGridApiContext();
  
    const handleValueChange = async ( { target }, row ) => {
        await apiRef.current.setEditCellValue({
            id: props.id,
            field: 'idloja',
            value: target.value.idloja,
        });
    };

    return (
        <Select
        sx={{width:'100%'}}
        onChange={(data,row) => handleValueChange(data,row)}
        >
            {props.colDef.valueOptions.map((e) => (
                <MenuItem value={e}>{e.codloja_int ? `${e.codloja_int} - ${e.nome}` : e.nome}</MenuItem>
            ))}
        </Select>
    )
}

export default function ProductPoster ( props ) {
    const [selectedCompany, setSelectedCompany] = useContext(SelectedCompanyContext)
    const [size] = useContext(SizeContext) 
    const [company] = useContext(CompanyContext)
    const [user] = useContext(UserContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [opLayout, setOpenLayout] = useState( false )
    const [state, setState] = useState({ open: false })
    const [layouts, setLayouts] = useState([])
    const [list, setList] = useState([])
    const [selected, setSelected] = useState([])
    const [print, setPrint] = useState({ open: false, list: [] })

    const update = async (idloja) => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/display/poster/product${idloja ? '?idloja='+idloja : ''}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({ data }) => setLayouts( data.map(e => ({ ...e, id: e.idcartazeamento })) ))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoading( false ))
    }
    
    const save = ( data ) => {
        setLoading(true)
        return new Promise((resolve, reject) => {
            axios
            .post(`${api}/api/manage/display/poster/product`,{
                idcartazeamento: data.idcartazeamento,
                nome: data.nome,
                idloja: data.idloja,
                all_store: data.all_store
            },{
                headers: {
                    Authorization: user.token
                }
            })
            .then(() => {
                update()
                resolve()
            })
            .catch(err => {
                toast.error(err?.response?.data?.message || err?.response?.data)
                setLoading(false)
                reject()
            })
        })
    }

    const openLayout = ( row ) => {
        const base = {
            headers: {
                Authorization: user.token
            },
            url_base: api,
            save_api: {
                method: "POST",
                url: `{url_base}/api/manage/display/poster/product`
            },
            load_api: {
                method: "GET",
                url: `{url_base}/api/manage/display/poster/product/${row.idcartazeamento}`
            },
            get_font_api: {
                method: "GET",
                url: `{url_base}/api/manage/display/font/{idfontetexto}`
            },
            load_font_api: {
                method: "GET",
                url: `{url_base}/api/manage/display/font`
            },
            create_font_api: {
                method: "POST",
                url: `{url_base}/api/manage/display/font`
            },
            update_font_api: {
                method: "PUT",
                url: `{url_base}/api/manage/display/font`
            },
            delete_font_api: {
                method: "DELETE",
                url: `{url_base}/api/manage/display/font/{idfontetexto}`
            }
        }

        const base64 = Buffer.from(JSON.stringify(base)).toString('base64');
        window.open(`https://cartaz.allon.cloud/${base64}?key=chave_teste`);
    }

    const mix = (idloja) => {
        if (!idloja) return setList([])
        setLoading(true)
        axios
        .get(`${api}/api/manage/mix/${idloja}`,{
            headers: {
                Authorization : user.token
            }
        })
        .then(response => setList(response.data))
        .catch(err => toast.error(err?.response?.data))
        .finally(() => setLoading(false))
    }

    const addToQueue = async ( st=undefined, s=undefined ) => {
        st = st || state
        s = s || selected

        try {

            const object = {
                ...st,
                idloja: selectedCompany?.idloja,
                lista: s.map(e => {
                    if ( !st.instantPrint ) return { idproduto: e.idproduto, idvariacaoproduto: e.idvariacaoproduto }

                    const valorvenda = vlrvenda({
                        valor: e.vlrvenda,
                        promocao: e.vlrpromocao,
                        desconto: e.vlrdesconto,
                        porcdesconto: e.porcdesconto
                    })

                    const vlrvendaecommerce = vlrvenda({
                        valor: e.vlrvendaecommerce,
                        promocao: e.vlrpromocaoecommerce,
                        desconto: e.vlrdescontoecommerce,
                        porcdesconto: e.porcdescontoecommerce
                    })

                    return {
                        vlrvenda: valorvenda,
                        vlrvendaecommerce: vlrvendaecommerce,
                        valor: parseFloat(e.vlrvenda || '0'),
                        valorecommerce: parseFloat(e.vlrvendaecommerce || '0'),
                        vlrdesconto: parseFloat(e.vlrvenda || '0') - valorvenda,
                        vlrdescontoecommerce: parseFloat(e.vlrvendaecommerce || '0') - vlrvendaecommerce,
                        percdesconto: ((parseFloat(e.vlrvenda || '0') - valorvenda) / (valorvenda || 1)) * 100,
                        percdescontoecommerce: ((parseFloat(e.vlrvendaecommerce || '0') - vlrvendaecommerce) / (vlrvendaecommerce || 1)) * 100,
                        id: e.idproduto || e.idprodutopai,

                        nome: e.nome,
                        descricao: e.descricao,
                        ean: e.ean,
                        unidmedida: e.unidmedida,
                        codproduto_int: e.codproduto_int,
                        nomemarca: e.nomemarca
                    }
                })
            } 

            const { data } = await axios.post(`${api}/api/manage/display/poster/product/queue`,object,{ headers: { Authorization: user.token } })

            if ( st.instantPrint ) {
                const mtz = company.find(({ matriz }) => matriz)

                const base = {
                    headers: {
                        Authorization: user.token
                    },
                    url_base: api,
                    load_api: {
                        method: "GET",
                        url: `{url_base}/api/manage/display/poster/product/queue/${data.unique_id}`
                    },
                    ecommerce: {
                        origin: `https://${mtz.usa_pagina_centralizada ? mtz.codigo_pagina_centralizada : selectedCompany.codigo}.allon.store`,
                        product_base: `/product/{id}${mtz.usa_pagina_centralizada ? '?store_code='+selectedCompany.codigo : ''}`
                    },
                    get_font_api: {
                        method: "GET",
                        url: `{url_base}/api/manage/display/font/{idfontetexto}`
                    },
                    load_font_api: {
                        method: "GET",
                        url: `{url_base}/api/manage/display/font`
                    }
                }
        
                const base64 = Buffer.from(JSON.stringify(base)).toString('base64');
                const browser = window.open(`https://cartaz.allon.cloud/${base64}?key=chave_teste`);
    
                const popupCheck = setInterval(() => {
                    if (browser?.closed) {
                        clearInterval(popupCheck);
                        axios.delete(`${api}/api/manage/display/poster/product/queue/${data.unique_id}?idloja=${selectedCompany?.idloja}`,{ headers: { Authorization: user.token } })
                        .then(() => {
                            if ( print.open ) openQueue()
                        })
                        .catch(() => {})
                    }
                }, 500);

            } else {
                toast.success(`Adicionado à Fila da loja "${selectedCompany?.nome}"`)
            }
        } catch ( err ) {
            console.log(err)
            toast.error(err?.response?.data?.message || err?.response?.data)
        }
    }

    const openQueue = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/display/poster/product/queue?idloja=${selectedCompany?.idloja}`,{ headers: { Authorization: user.token }})
        .then(({ data }) => setPrint({ open: true, list: data.map(e => ({ ...e, id: e.unique_id, dtcriacao: new Date(e.dtcriacao) })) }))
        .catch(err => toast.error( err?.response?.data?.message || err?.response?.data ))
        .finally(() => setLoading( false ))
    }

    const remove = (row, type='') => {
        return new Promise(( resolve, reject ) => {
            axios
            .delete(`${api}/api/manage/display/poster/product${type}/${row.unique_id || row.idcartazeamento}?idloja=${row.idloja}`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(( response ) => {
                if ( type ) openQueue()
                else update()

                toast.success("Removido")
                resolve()
            })
            .catch(err => {
                reject()
                toast.error(err?.response?.data?.message || err?.response?.data)
            })
        })
    }

    const theme = useTheme()

    useEffect(() => {
        if (!user.all_store && !selectedCompany?.idloja) {
            update()
            setList([])
            return 
        }

        mix(!user.all_store ? user.idloja : selectedCompany?.idloja)
        update(!user.all_store ? user.idloja : selectedCompany?.idloja)
    },[selectedCompany])

    const confirmRef = useRef()

    return (
        <div
        // style={{
        //     height: 'calc(100% - 70px)'
        // }}
        >
            <Modal
            open={ print.queue?.open }
            onClose={() => setPrint({ ...print, queue: undefined })}
            sx={{
                display:'flex',
                justifyContent:"center",
                alignItems:"center"
            }}
            >
                <div
                style={{
                    padding:'15px 20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    minWidth:'700px',
                    height:'400px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'space-between'
                }}>
                    <Table 
                    list={(print.queue?.lista || []).filter(e => e).map(e => ({
                        ...e,
                        adicionado: e.adicionado,
                        adicionado_ecommerce: e.ecommerce,
                        promocao: (
                            (parseFloat(e.vlrpromocao || 0) > 0 && parseFloat(e.vlrpromocao || 0) < parseFloat(e.vlrvenda || 0))
                            || parseFloat(e.vlrdesconto) || parseFloat(e.porcdesconto) ? true : false),
                        promocao_ecommerce: (
                            (parseFloat(e.vlrpromocaoecommerce || 0) > 0 && parseFloat(e.vlrpromocaoecommerce || 0) < parseFloat(e.vlrvendaecommerce || 0))
                            || parseFloat(e.vlrdescontoecommerce) || parseFloat(e.porcdescontoecommerce) ? true : false)
                    }))}
                    header={[
                        {
                            "field":"codproduto_int",
                            "headerName":"Código ERP",
                            flex: 0.3
                        },
                        {
                            "field":"nome",
                            "headerName":"Nome",
                            flex: 1
                        },
                        {
                            "field":"nomemarca",
                            "headerName":"Marca",
                            flex: 0.7
                        },
                        {
                            "field":"categoria",
                            "headerName":"Categoria",
                            flex: 1
                        },
                        {
                            "field":"ean",
                            "headerName":"Cod. Barra",
                            flex: 0.4
                        },{
                            "field":"variacao",
                            "headerName":"Variação"
                        },{
                            "field":"adicionado",
                            "headerName":"Adicionado",
                            type: 'boolean'
                        },{
                            "field":"adicionado_ecommerce",
                            "headerName":"Ecommerce",
                            type: 'boolean'
                        },{
                            "field":"promocao",
                            "headerName":"Promoção",
                            type: 'boolean'
                        },{
                            "field":"promocao_ecommerce",
                            "headerName":"Promo Ecommerce",
                            type: 'boolean'
                        },{
                            "field":"vlrvenda",
                            "headerName":"Valor",
                            type: 'numeric'
                        },{
                            "field":"vlrvendaecommerce",
                            "headerName":"Valor Ecommerce",
                            type: 'numeric'
                        },{
                            "field":"vlrpromocao",
                            "headerName":"Vlr. Promoção",
                            type: 'numeric'
                        },{
                            "field":"vlrpromocaoecommerce",
                            "headerName":"Vlr. Promoção Ecommerce",
                            type: 'numeric'
                        },{
                            "field":"vlrdesconto",
                            "headerName":"Desconto",
                            type: 'numeric'
                        },{
                            "field":"vlrdescontoecommerce",
                            "headerName":"Desconto Ecommerce",
                            type: 'numeric'
                        }
                    ]}
                    // rowsPerPage={5}
                    pageSizeOptions={[20, 50, 100]}
                    page={0}
                    onRowClick={(data, event) => {}}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                variacao: false,
                                adicionado: false,
                                adicionado_ecommerce: false,
                                promocao: false,
                                promocao_ecommerce: false,
                                categoria: false,
                                vlrvenda: false,
                                vlrvendaecommerce: false,
                                vlrpromocao: false,
                                vlrpromocaoecommerce: false,
                                vlrdesconto: false,
                                vlrdescontoecommerce: false,
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={true}
                    sx={{
                        height:'100%'
                    }}
                    // height={`calc(100vh - ${size.width <= 500 ? '170px' : '120px'})`}
                    density={'compact'}
                    csvOptions={{ delimiter: ';' }}
                    />
                </div>
            </Modal>

            <Modal
            open={ print.open }
            onClose={() => setPrint({ open: false, list: [] })}
            sx={{
                display:'flex',
                justifyContent:"center",
                alignItems:"center"
            }}
            >
                <div
                style={{
                    padding:'15px 20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    minWidth:'700px',
                    height:'400px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'space-between'
                }}>
                    <Editable 
                    addButton={false}
                    rows={print.list}
                    density='compact'
                    columns={[
                        {
                            field: 'nome',
                            headerName: 'Nome',
                            flex: 1
                        },
                        {
                            field: 'dtcriacao',
                            headerName: "Criação",
                            type: 'dateTime',
                            flex: 0.7
                        }
                    ]}
                    actions={
                        [
                            <GridActionsCellItem
                            icon={<VisibilityIcon />}
                            label="Visualizar"
                            className="textPrimary"
                            color="inherit"
                            onClick={( row ) => setPrint({ ...print, queue: {
                                open: true,
                                ...row,
                                lista: row.lista.map(e => ( list.find(({ idproduto, idvariacaoproduto }) => (( e.idproduto && e.idproduto === idproduto ) || ( e.idvariacaoproduto && e.idvariacaoproduto === idvariacaoproduto )))))
                            }})}
                            />,
                            <GridActionsCellItem
                            icon={<LocalPrintshopIcon />}
                            label="Imprimir"
                            className="textPrimary"
                            color="inherit"
                            onClick={( row ) => addToQueue({
                                    instantPrint: true,
                                    unique_id: row.unique_id
                                }, 
                                row.lista.map(e => ( list.find(({ idproduto, idvariacaoproduto }) => (( e.idproduto && e.idproduto === idproduto ) || ( e.idvariacaoproduto && e.idvariacaoproduto === idvariacaoproduto )))))
                            )}
                            />
                        ]
                    }
                    actionsHeader='Ações'
                    actionsWidth={100}
                    pageSizeOptions={[20, 50, 100]}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    onRowUpdate={( row ) => save( row )}
                    onRowDelete={( row ) => remove( row, '/queue' )}
                    disableEdit
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    sx={{
                        height:'100%'
                    }}
                    />
                </div>
            </Modal>

            <Confirm ref={confirmRef} />

            <Modal
            open={ state.open }
            onClose={() => setState({ open:false })}
            sx={{
                display:'flex',
                justifyContent:"center",
                alignItems:"center"
            }}>
                <div
                style={{
                    padding:'15px 20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    // minWidth:'500px',
                    // height:'400px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'space-between'
                }}>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={layouts}
                    sx={{ 
                        width: 300,
                        // marginRight:'5px',
                        height: '32px'
                        // marginRight:'10px',
                        // width: 'inherit'
                    }}
                    size='small'
                    getOptionLabel={e => e.nome}
                    onChange={(event, value) => setState({ ...state, idcartazeamento: value?.idcartazeamento })}
                    renderInput={(params) => <TextField {...params} size='small' label="Layout" />}
                    />

                    <FormControlLabel
                    sx={{ color:'text.primary', margin: '10px 0px 0px 0px' }}
                    control={<Switch 
                    checked={state.instantPrint}
                    onChange={({ target }) => setState({ ...state, instantPrint: target.checked })}
                    />} label="Impressão instantânea" />

                    {
                        !state.instantPrint ? 
                        <>
                        <FormControlLabel
                        sx={{ color:'text.primary', margin: '0px' }}
                        control={<Switch 
                        checked={state.freeze}
                        onChange={({ target }) => setState({ ...state, freeze: target.checked })}
                        />} label="Congelar layout" />

                        <TextField 
                        value={state.nome}
                        onChange={({ target }) => setState({ ...state, nome: target.value })}
                        label='Nome da impressão'
                        size="small"
                        />
                        </> : 
                        <></>
                    }

                    <Button
                    startIcon={ state.instantPrint ? <LocalPrintshopIcon /> : <FactCheckIcon />}
                    color='success'
                    sx={{
                        marginTop:'20px'
                    }}
                    size='small'
                    variant='contained'
                    disabled={!state.idcartazeamento}
                    onClick={() => addToQueue()}
                    >
                        {state.instantPrint ? 'Imprimir' : 'Adicionar à fila'}
                    </Button>
                </div>
            </Modal>

            <Modal
            open={ opLayout }
            onClose={() => setOpenLayout(false)}
            sx={{
                display:'flex',
                justifyContent:"center",
                alignItems:"center"
            }}
            >
                <div
                style={{
                    padding:'15px 20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    minWidth:'500px',
                    height:'400px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'space-between'
                }}>
                    <Editable 
                    addButton
                    rows={layouts}
                    density='compact'
                    columns={[
                        {
                            field: 'nome',
                            headerName: 'Nome',
                            flex: 1,
                            editable: true
                        },
                        {
                            field: 'idloja',
                            headerName: 'Loja',
                            type: 'singleSelect',
                            valueOptions: company,
                            width: 120,
                            editable: true,
                            renderEditCell: (params) => <CustomTypeEditComponent {...params} />,
                            renderCell: ( params ) => <Typography>{params.row.loja}</Typography>
                        },
                        {
                            field: 'all_store',
                            headerName: 'Todas lojas',
                            type:'boolean',
                            width: 120,
                            editable: true
                        }
                    ]}
                    actions={
                        [
                            <GridActionsCellItem
                            icon={<SettingsIcon />}
                            label="Configurar"
                            className="textPrimary"
                            color="inherit"
                            onClick={( row ) => openLayout( row )}
                            />
                        ]
                    }
                    actionsHeader='Ações'
                    actionsWidth={100}
                    pageSizeOptions={[20, 50, 100]}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    onRowUpdate={( row ) => save( row )}
                    onRowDelete={( row ) => remove( row )}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    sx={{
                        height:'100%'
                    }}
                    />
                </div>
            </Modal>

            <div
            style={{
                width:'100%',
                display:'flex',
                // marginBottom:'5px',
                marginTop:'5px',
                height: "46px"
            }}
            >
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={company.map((e) => ({...e, label: e.nome}))}
                sx={{ 
                    width: 300,
                    marginRight:'5px',
                    height: '32px'
                    // marginRight:'10px',
                    // width: 'inherit'
                }}
                size='small'
                value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                onChange={(event, value) => setSelectedCompany(value || false)}
                renderInput={(params) => <TextField {...params} size='small' label="Empresa" />}
                disabled={!user.all_store}
                />

                <Button
                startIcon={<SyncIcon />}
                color='primary'
                variant='outlined'
                onClick={() => update(!user.all_store ? user.idloja : selectedCompany?.idloja)}
                sx={{marginRight:'5px', height:'40px'}}
                size='small'
                disabled={!selectedCompany}
                >
                    Atualizar
                </Button>

                <Button
                startIcon={<PostAddIcon />}
                color='primary'
                variant='outlined'
                size='small'
                disabled={!selected.length}
                sx={{marginRight:'5px', height:'40px'}}
                onClick={() => setState({ open:true })}
                >
                    nova impressão
                </Button>

                <Button
                startIcon={<ReceiptLongIcon />}
                color='primary'
                variant='outlined'
                size='small'
                disabled={!selectedCompany?.idloja}
                sx={{marginRight:'5px', height:'40px'}}
                onClick={openQueue}
                >
                    Impressões
                </Button>
                
            </div>

            <Table 
            list={list.filter(e => e).map(e => ({
                ...e,
                adicionado: e.adicionado,
                adicionado_ecommerce: e.ecommerce,
                promocao: (
                    (parseFloat(e.vlrpromocao || 0) > 0 && parseFloat(e.vlrpromocao || 0) < parseFloat(e.vlrvenda || 0))
                    || parseFloat(e.vlrdesconto) || parseFloat(e.porcdesconto) ? true : false),
                promocao_ecommerce: (
                    (parseFloat(e.vlrpromocaoecommerce || 0) > 0 && parseFloat(e.vlrpromocaoecommerce || 0) < parseFloat(e.vlrvendaecommerce || 0))
                    || parseFloat(e.vlrdescontoecommerce) || parseFloat(e.porcdescontoecommerce) ? true : false)
            }))}
            header={[
                {
                    "field":"codproduto_int",
                    "headerName":"Código ERP",
                    flex: 1
                },
                {
                    "field":"nome",
                    "headerName":"Nome",
                    flex: 1
                },
                {
                    "field":"nomemarca",
                    "headerName":"Marca",
                    flex: 1
                },
                {
                    "field":"categoria",
                    "headerName":"Categoria",
                    flex: 1
                },
                {
                    "field":"ean",
                    "headerName":"Cod. Barra",
                    flex: 1
                },{
                    "field":"variacao",
                    "headerName":"Variação"
                },{
                    "field":"adicionado",
                    "headerName":"Adicionado",
                    type: 'boolean'
                },{
                    "field":"adicionado_ecommerce",
                    "headerName":"Ecommerce",
                    type: 'boolean'
                },{
                    "field":"promocao",
                    "headerName":"Promoção",
                    type: 'boolean'
                },{
                    "field":"promocao_ecommerce",
                    "headerName":"Promo Ecommerce",
                    type: 'boolean'
                },{
                    "field":"vlrvenda",
                    "headerName":"Valor",
                    type: 'numeric',
                    flex: 1
                },{
                    "field":"vlrvendaecommerce",
                    "headerName":"Valor Ecommerce",
                    type: 'numeric',
                    flex: 1
                },{
                    "field":"vlrpromocao",
                    "headerName":"Vlr. Promoção",
                    type: 'numeric',
                    flex: 1
                },{
                    "field":"vlrpromocaoecommerce",
                    "headerName":"Vlr. Promoção Ecommerce",
                    type: 'numeric',
                    flex: 1
                },{
                    "field":"vlrdesconto",
                    "headerName":"Desconto",
                    type: 'numeric',
                    flex: 1
                },{
                    "field":"vlrdescontoecommerce",
                    "headerName":"Desconto Ecommerce",
                    type: 'numeric',
                    flex: 1
                }
            ]}
            // rowsPerPage={5}
            pageSizeOptions={[20, 50, 100]}
            check={true}
            page={0}
            onCheck={( data ) => setSelected( data )}
            onRowClick={(data, event) => {}}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        nomemarca: false,
                        categoria: false,
                        variacao: false,
                        adicionado: false,
                        adicionado_ecommerce: false,
                        promocao: true,
                        vlrvenda: true,
                        vlrpromocao: true,
                        vlrdesconto: true

                        // id: false,
                        // variacao: size.width <= 500 ? false : true,
                        // adicionado: size.width <= 500 ? false : true,
                        // adicionado_ecommerce: size.width <= 500 ? false : true,
                        // promocao: size.width <= 500 ? false : true,
                        // promocao_ecommerce: size.width <= 500 ? false : true,
                        // categoria: size.width > 500,
                        // vlrvenda: false,
                        // vlrvendaecommerce: false,
                        // vlrpromocao: false,
                        // vlrpromocaoecommerce: false,
                        // vlrdesconto: false,
                        // vlrdescontoecommerce: false,
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            toolbar={true}
            csvOptions={{ delimiter: ';' }}
            sx={{
                height:'100%'
            }}
            height={`calc(100vh - ${size.width <= 500 ? '170px' : '120px'})`}
            density={'compact'}
            customToolBar={
                <>
                <Button
                startIcon={<ListAltIcon />}
                // variant='outlined'
                size='small'
                onClick={() => setOpenLayout(true)}
                color='success'
                >
                    Listar Cartazes
                </Button>
                </>
            }
            />

        </div>
    )
}