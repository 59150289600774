import React, { 
    useContext,
    useState,
    forwardRef
} from "react";

import { 
    Autocomplete,
    Button, 
    Checkbox, 
    FormControlLabel, 
    IconButton, 
    ListItem, 
    ListItemText, 
    Modal, 
    Switch, 
    TextField, 
    Typography
} from "@mui/material";

import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import CategoryIcon from '@mui/icons-material/Category';
import DoneIcon from '@mui/icons-material/Done';

import CloseIcon from '@mui/icons-material/Close';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';

import { 
    CompanyContext,
    LoadingContext,
    SizeContext, 
    UserContext
} from "../../../context/handleContext";

import Table from '../../table/virtualized'
import { useTheme } from '@mui/material/styles';
import { useReducer } from "react";
import axios from "axios";
import { api } from "../../../utils/defaults";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { float } from "../../../utils/input";
import { useDropzone } from "react-dropzone";
import CopyToClipboard from "react-copy-to-clipboard";
import Editor from './editor'
import { useRef } from "react";

const tamanhos_exemplo = {
    "Cabeçalho": {
        "Logo": 12,
        "Fonte oferta": 4,
        "Fonte produto": 5,
        "Fonte centavos": 2.5,
        "Fonte Fila": 7
    },
    "Tabela": {
        "Fonte Cabeçalho": 3,
        "Fonte Corpo": 3,
        "Fonte Oferta": 2
    },
    "Marketing": {
        "Largura Video": 30,
        "Altura Video": 30,
        "Tamanho QR Code": 15,
        "Fonte QR Code": 1
    }
}

const Categorias = (props) => {
    const [user, setUser] = useContext(UserContext)
    const [size, setSize] = useContext(SizeContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [open, setOpen] = useState(props.open)
    const [list, setList] = useState(props.data || [])

    const [ignore, forceUpdate] = useReducer(x => x + 1, 0)
    const theme = useTheme()

    const update = () => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/price/category`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => props.setData(response.data))
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    const save = () => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/price/category`,list,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            update()
            toast.success('Salvo')
        })
        .catch(err => toast.error(err.response.data))
        .finally(() => setLoading(false))
    }

    useEffect(() => setList(props.data), [props.data])

    useEffect(() => {
        if (!list.length) update()
    }, [])

    return (
        <>
            <Button
            startIcon={<CategoryIcon />}
            color='primary'
            variant='contained'
            onClick={() => setOpen(true)}
            sx={{marginRight:'10px'}}
            size='small'
            >
                Setores
            </Button>

            <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                display:'flex',
                justifyContent:'center',
                width: '100vw',
                height: '100vh',
                alignItems:'center'
            }}
            >
                <div
                style={{
                    padding:'20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    height:'fit-content',
                    margin:'10px'
                }}
                >
                    <div
                    style={{
                        width:'100%',
                        margin: '0px 0px 10px 0px',
                        display:'flex',
                        justifyContent:'space-between'
                    }}
                    >
                        <div
                        style={{
                            display:'flex'
                        }}
                        >
                            <Button
                            startIcon={<AddIcon />}
                            color='success'
                            variant='outlined'
                            onClick={() => {
                                setLoading(true)
                                setList([])
                                setTimeout(() => {
                                    setList([ { nome: '', remove: false, new: true }, ...list ].map((e, index) => ({ ...e, id:index })))
                                    setLoading(false)
                                }, 500);
                            }}
                            sx={{ marginRight:'10px' }}
                            size='small'
                            >
                                Adicionar
                            </Button>

                            <Button
                            startIcon={<SyncIcon />}
                            color='primary'
                            variant='outlined'
                            onClick={update}
                            sx={{marginRight:'10px'}}
                            size='small'
                            >
                                Atualizar
                            </Button>
                        </div>

                        <Button
                        startIcon={<DoneIcon />}
                        color='success'
                        variant='contained'
                        onClick={save}
                        // sx={{marginRight:'10px'}}
                        size='small'
                        >
                            Salvar
                        </Button>

                        {/* <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={company.map((e) => ({...e, label: e.nome}))}
                        sx={{ 
                            width: size.width > 745 ? 300 : '100%',
                            marginBottom: size.width < 745 && '10px',
                            marginRight:'10px'
                        }}
                        size='small'
                        value={selectedCompany ? {...selectedCompany, label: selectedCompany.nome} : {label:'Todas'}}
                        onChange={(event, value) => setSelectedCompany(value || false)}
                        renderInput={(params) => <TextField {...params} label="Empresa" />}
                        disabled={!user.all_store}
                        /> */}
                    </div>

                    <Table 
                    list={list}
                    header={[
                        {
                            "field": "nome",
                            "headerName": "Nome",
                            flex: 1,
                            renderCell: (params) => {
                                return (
                                    <TextField 
                                    label=''
                                    defaultValue={params.row.nome}
                                    onChange={({target}) => {
                                        let l = list
                                        l[params.id].nome = target.value?.toUpperCase()
                                        setList(l)
                                        forceUpdate()
                                    }}
                                    size='small'
                                    />
                                )
                            },
                        },{
                            field: 'ativo',
                            headerName: 'Ativo',
                            renderCell: (params) => {
                                return (
                                    <Switch
                                    defaultChecked={params.row.ativo}
                                    onChange={({target}) => {
                                        let l = list
                                        l[params.id].ativo = target.checked
                                        setList(l)
                                    }}
                                    />
                                )
                            },
                            align:'right',
                            flex: 0.25
                        },{
                            field: 'remove',
                            headerName: 'Remover',
                            renderCell: (params) => {
                                return (
                                    <Checkbox
                                    defaultChecked={params.row.remove}
                                    onChange={({target}) => {
                                        if (params.row.new) {
                                            let l = list
                                            l.splice(params.id, 1)
                                            setLoading(true)
                                            setList([])
                                            setTimeout(() => {
                                                setList(l.map((e,index) => ({...e, id: index})))
                                                setLoading(false)
                                            }, 500);
                                            return
                                        }
        
                                        let l = list
                                        l[params.id].remove = target.checked
                                        setList(l)
                                    }}
                                    />
                                )
                            },
                            align:'right',
                            flex: 0.25
                        }
                    ]}
                    rowsPerPage={5}
                    pageSizeOptions={[5, 10, 20]}
                    check={false}
                    page={0}
                    // onCheck={console.log}
                    onRowClick={(data, event) => {
                        if (event.target.localName === 'button') return
                    }}
                    disableRowSelectionOnClick={true}
                    autoHeight={false}
                    initialState = {{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                desk: false
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        }
                    }}
                    toolbar={size.width > 650 ? true : false}
                    sx={{
                        height:'100%'
                    }}
                    // height='calc(100vh - 200px)'
                    />
                </div>
            </Modal>
        </>
    )
}

const Home = forwardRef((props, ref) => {
    const [loading, setLoading] = useContext(LoadingContext)
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext)
    const [company, setCompany] = useContext(CompanyContext)
    const [ignore, forceUpdate] = useReducer( x => x + 1, 0 )
    const [table, setTable] = useState({ tamanhos: tamanhos_exemplo })
    const [fundo, setFundo] = useState({})
    const [list, setList] = useState([])
    const [category, setCategory] = useState([])
    const [preferences, setPreferences] = useState([])
    const [font, setFont] = useState([])

    useEffect(() => {
        setLoading(true)
        axios
        .get(`${api}/api/manage/price/font`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => setFont(data))
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))

        const controller = update()

        if (props.main  === false) {
            axios
            .get(`${api}/api/manage/price/preferences`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({data}) => setPreferences(data))
            .catch(err => toast.error(err.response.data))

            axios
            .get(`${api}/api/manage/price/category`,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({data}) => setCategory(data))
            .catch(err => toast.error(err.response.data))
        }

        return () => {
            controller.abort()
        }
    },[])

    const background = useDropzone({
        maxSize: 3145728,
        onDropAccepted: e => {
            const reader = new FileReader();

            reader.onloadend = () => {
                setFundo({
                    file: e[0],
                    url: reader.result
                })
                setLoading(false)
            };
            setLoading(true)
            reader.readAsDataURL(e[0])
        }
    })

    const update = (idtabelapreco=undefined) => {
        const controller = new AbortController()

        setLoading(true)
        axios
        .get(`${api}/api/manage/price/device${idtabelapreco ? `?idtabelapreco=${idtabelapreco}` : ''}`,{
            headers: {
                Authorization: user.token
            },
            signal: controller.signal
        })
        .then(({data}) => {
            if (idtabelapreco) setTable({...data, open: true})
            else setList(data)
        })
        .catch(({response}) => toast.error(response?.data))
        .finally(() => setLoading(false))

        return controller
    }

    const save = () => {
        if (table.idtabelapreco) {
            setLoading(true)
            axios
            .put(`${api}/api/manage/price/device`,table,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({data}) => {
                update()
                update(data.idtabelapreco)
                toast.success('Sucesso')
            })
            .catch(({response}) => toast.error(response.data))
            .finally(() => setLoading(false))
        } else {
            setLoading(true)
            axios
            .post(`${api}/api/manage/price/device`,table,{
                headers: {
                    Authorization: user.token
                }
            })
            .then(({data}) => {
                update()
                update(data.idtabelapreco)
                toast.success('Sucesso')
            })
            .catch(({response}) => toast.error(response.data))
            .finally(() => setLoading(false))
        }
    }

    const leave = (idtabelapreco) => {
        setLoading(true)
        axios
        .post(`${api}/api/manage/price/device/leave/${idtabelapreco}`,table,{
            headers: {
                Authorization: user.token
            }
        })
        .then(({data}) => {
            update()
            toast.success('Sucesso')
        })
        .catch(({response}) => toast.error(response.data))
        .finally(() => setLoading(false))
    }

    const theme = useTheme()
    const editorRef = useRef()

    React.useImperativeHandle(ref, () => ({ 
        open: idtabelapreco => update(idtabelapreco)
    }))

    return (
        <>
            <Modal
            open={table.open}
            onClose={() => setTable({open: false})}
            sx={{
                width:'100%',
                height:'100%',
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
            }}
            >
                <div
                style={{
                    padding:size.width <= 500 ? '5px' : '20px',
                    borderRadius:'10px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
                    display:'flex',
                    // flexWrap:"wrap",
                    overflowY:'auto',
                    maxHeight:'95vh',
                    width: size.width <= 500 ? '95%' : "auto",
                    overflowX: 'hidden',
                    flexDirection: size.width <= 500 ? 'column' : 'row'
                }}
                >
                    <div
                    style={{}}
                    >
                        {size.width <= 500 &&
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'space-between',
                            marginBottom:'20px'
                        }}
                        >
                            <Button
                            size='small'
                            variant="contained"
                            color='success'
                            onClick={save}
                            >
                                salvar
                            </Button>

                            <Button
                            size='small'
                            variant="contained"
                            color='error'
                            onClick={() => setTable({open: false})}
                            >
                                fechar
                            </Button>
                        </div>
                        }
                        {size.width <= 500 &&
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'right'
                        }}
                        >
                            <TextField 
                            label='Latitude'
                            sx={{width:'100%'}}
                            size='small'
                            value={table.lat}
                            onChange={({target}) => setTable({...table, lat: target.value})}
                            />

                            <TextField 
                            label='Longitude'
                            sx={{width:'100%'}}
                            size='small'
                            value={table.lng}
                            onChange={({target}) => setTable({...table, lng: target.value})}
                            />
                        </div>}

                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={preferences}
                        sx={{ width: '350px', marginBottom:'10px' }}
                        value={
                            preferences
                            .find(({idtabelaprecopreferencia}) => (idtabelaprecopreferencia === table.idtabelaprecopreferencia))
                        }
                        getOptionLabel={(option) => option.nomepreferencia}
                        onChange={(event, value) => setTable({...table, idtabelaprecopreferencia: value?.idtabelaprecopreferencia})}
                        renderInput={(params) => <TextField {...params} label="Tema" />}
                        size='small'
                        renderOption={(props, option) => (
                            // <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            //     {option.nomepreferencia}
                            // </Box>
                            <ListItem
                            {...props}
                            secondaryAction={
                                <IconButton 
                                onClick={() => editorRef.current?.open(option.idtabelaprecopreferencia)}
                                edge="end" aria-label="delete">
                                    <VisibilityOutlinedIcon />
                                </IconButton>
                            }
                            >
                            {/* <ListItemAvatar>
                                <Avatar>
                                <FolderIcon />
                                </Avatar>
                            </ListItemAvatar> */}
                            <ListItemText
                                primary={option.nomepreferencia}
                                // secondary={secondary ? 'Secondary text' : null}
                            />
                            </ListItem>
                        )}
                        />

                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={company.map((e) => ({...e, label: e.nome}))}
                        sx={{ width: '350px', marginBottom:'10px' }}
                        value={
                            company.map((e) => ({...e, label: e.nome}))
                            .find(({idloja}) => (idloja === table.idloja))
                        }
                        onChange={(event, value) => setTable({...table, idloja: value?.idloja})}
                        renderInput={(params) => <TextField {...params} label="Loja" />}
                        size='small'
                        />

                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={category.map((e) => ({...e, label: e.nome}))}
                        sx={{ width: '350px' }}
                        value={
                            category.map((e) => ({...e, label: e.nome}))
                            .find(({idtabelaprecosetor}) => (idtabelaprecosetor === table.idtabelaprecosetor))
                        }
                        onChange={(event, value) => setTable({...table, idtabelaprecosetor: value?.idtabelaprecosetor})}
                        renderInput={(params) => <TextField {...params} label="Setor" />}
                        size='small'
                        />

                        <div
                        style={{
                            display:'flex',
                            justifyContent: 'space-between',
                            margin:'10px 0px 0px 10px'
                        }}
                        >
                            <FormControlLabel 
                            sx={{
                                color: 'text.primary'
                            }}
                            control={
                                <Checkbox 
                                color='primary' 
                                onChange={({target}) => setTable({...table, mostrar_preco_ecommerce: target.checked})}
                                checked={table.mostrar_preco_ecommerce}
                                // disabled
                                />
                            } 
                            label="Mostrar E-commerce" />

                            <Button>
                                Monitor
                            </Button>
                        </div>

                        <div
                        style={{
                            maxWidth:'100%',
                            marginTop:'10px',
                            opacity: 0.5//table.idtabelapreco ? 1 : 0.5
                        }}
                        >
                            <section className="container">
                                <div 
                                {...background.getRootProps({className: 'dropzone'})}
                                // style={{position:'relative'}}
                                >
                                    {(fundo.url || fundo.tipo_fundo) &&
                                    <div
                                    style={{
                                        justifyContent:'right',
                                        display:'flex',
                                        position:'absolute',
                                        zIndex:99
                                    }}>
                                        <IconButton
                                        onClick={() => setFundo({ })}>
                                            <CloseIcon 
                                            color='error' 
                                            sx={{
                                                boxShadow:'0px 0px 10px 0px black'
                                            }} />
                                        </IconButton>
                                    </div>}
                                    {(fundo.url || fundo.tipo_fundo) ?
                                    <img 
                                    style={{
                                        objectFit:'contain',
                                        width:'100%',
                                        maxWidth:'350px'
                                    }}
                                    src={fundo.url || `data:${fundo.tipo_fundo};base64, ${fundo.fundo}`}
                                    /> :
                                    <div
                                    style={{
                                        height:'266px',
                                        border: '5px dotted black',
                                        borderRadius:'10px',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        flexDirection:'column'
                                    }}>
                                        <AddPhotoAlternateIcon sx={{fontSize:'100px', color:'black'}} />
                                        <Typography
                                        sx={{
                                            textAlign:'center',
                                            color: 'text.primary'
                                        }}
                                        >Plano de Fundo</Typography>
                                    </div>}
                                    {/* {table.idtabelapreco && <input {...background.getInputProps()} />} */}
                                    {/* {table.idtabelapreco && <Typography
                                    sx={{
                                        textAlign:'center',
                                        color: 'text.primary'
                                    }}
                                    >Clique ou arraste o arquivo</Typography>} */}
                                </div>
                            </section>
                        </div>
                    </div>

                    <div
                    style={{
                        marginLeft:size.width > 500 && '20px'
                    }}
                    >
                        {size.width > 500 &&
                        <div
                        style={{
                            display:'flex',
                            justifyContent:'right'
                        }}
                        >
                            <TextField 
                            label='Latitude'
                            sx={{width:'130px'}}
                            size='small'
                            value={table.lat}
                            onChange={({target}) => setTable({...table, lat: target.value})}
                            />

                            <TextField 
                            label='Longitude'
                            sx={{width:'130px'}}
                            size='small'
                            value={table.lng}
                            onChange={({target}) => setTable({...table, lng: target.value})}
                            />

                            <Button
                            size='small'
                            variant="contained"
                            color='success'
                            onClick={save}
                            >
                                salvar
                            </Button>
                        </div>}
                        <div
                        style={{
                            maxWidth: '450px',
                            padding:size.width > 500 ? '20px' : '5px',
                            margin:size.width > 500 && '10px 0px 0px 10px',
                            border: `1px solid rgba(0,0,0,0.5)`,
                            borderRadius:'10px',

                            display:size.width <= 500 && 'flex',
                            flexWrap:size.width <= 500 && 'wrap'
                        }}
                        >
                            {Object.entries(tamanhos_exemplo).map(e => (
                                <div>
                                <Typography
                                sx={{
                                    color: 'text.primary',
                                    fontSize: '18px',
                                    marginBottom:'10px'
                                }}
                                >{e[0]}</Typography>
                                {Object.entries(e[1]).map(i => (
                                    <TextField 
                                    value={table.tamanhos && table.tamanhos[e[0]] ? table.tamanhos[e[0]][i[0]] : i[1]}
                                    label={'%'}
                                    helperText={i[0]}
                                    size='small'
                                    sx={{ width: '150px' }}
                                    onChange={({target}) => {
                                        const t = table
                                        t.tamanhos = t.tamanhos || {}
                                        t.tamanhos[e[0]] = {
                                            ...t.tamanhos[e[0]],
                                            [i[0]]: float(target.value)
                                        }
                                        setTable(t)
                                        forceUpdate()
                                    }}
                                    />
                                ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>

            {props.main === false ? <></> : 
            <div  
            style={{
                overflowY:'auto',
                padding:size.width <= 500 ? '5px' : '20px'
            }}
            >
                <div
                style={{
                    width:'100%',
                    // margin: '10px 0px',
                    display:'flex',
                    justifyContent:'space-between',
                    marginBottom: '10px',
                    flexWrap:"wrap"
                }}
                >
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width: size.width <= 500 && '100%',
                        marginBottom: size.width <= 500 && '10px'
                    }}
                    >
                        <Button
                        startIcon={<AddIcon />}
                        color='success'
                        variant='outlined'
                        onClick={() => setTable({tamanhos: tamanhos_exemplo, open: true})}
                        sx={{marginRight:'10px'}}
                        size='small'
                        >
                            Adicionar
                        </Button>
    
                        <Button
                        startIcon={<SyncIcon />}
                        color='primary'
                        variant='outlined'
                        onClick={() => update()}
                        // sx={{marginRight:'10px'}}
                        size='small'
                        >
                            Atualizar
                        </Button>
                    </div>
    
                    <div
                    style={{
                        display:'flex',
                        justifyContent:'space-between',
                        width: size.width <= 500 && '100%'
                    }}>
                        <Categorias 
                        data={category}
                        setData={e => setCategory(e)}
                        />
    
                        <Editor 
                        ref={editorRef}
                        showButton
                        data={preferences}
                        setData={e => setPreferences(e)}
                        />
                    </div>
    
                </div>
    
                <Table 
                list={list}
                header={[
                    {
                        "field": "idtabelapreco",
                        "headerName": "ID"
                    },
                    {
                        "field": "loja",
                        "headerName": "Loja",
                        flex: 1
                    },
                    {
                        "field": "setor",
                        "headerName": "Setor",
                        flex: 1
                    }, {
                        "field": "mostrar_preco_ecommerce",
                        "headerName": "Mostra Ecommerce",
                        flex: 0.5,
                        type: 'boolean'
                    }, {
                        "field": "application",
                        "headerName": "App Online",
                        flex: 0.5,
                        type: 'boolean'
                    }, {
                        "field": "device",
                        "headerName": "Disp. online",
                        flex: 0.5,
                        type: 'boolean'
                    }, {
                        "field": "is_used",
                        "headerName": "Em uso",
                        flex: 0.5,
                        type: 'boolean'
                    }, {
                        "field": "key_code",
                        "headerName": "Chave",
                        flex: 1,
                        renderCell: (params) => {
                            return (
                                <CopyToClipboard 
                                onCopy={() => {
                                    toast.success('Copiado')
                                }}
                                text={params.value}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    style={{ marginLeft: 16 }}
                                    tabIndex={params.hasFocus ? 0 : -1}
                                    // onClick={() => console.log()}
                                >
                                    Copiar
                                </Button>
                                </CopyToClipboard>
                            )
                        },
                    }, {
                        "field": "codloja_int",
                        "headerName": "Cod. Loja"
                    }, {
                        "field": "ip",
                        "headerName": "IP"
                    }, {
                        "field": "mac",
                        "headerName": "MAC"
                    }, {
                        "field": "lat",
                        "headerName": "Latitude"
                    }, {
                        "field": "lng",
                        "headerName": "Longitude"
                    }, {
                        "field": "nomedispositivo",
                        "headerName": "Nome",
                        hide: true,
                        flex: 0.3
                    }, {
                        "field": "",
                        "headerName": "Liberar",
                        renderCell: (params) => {
                            return (
                                <IconButton
                                color='success'
                                onClick={() => leave(params.row.idtabelapreco)}
                                disabled={!params.row.is_used}
                                >
                                    <GppGoodOutlinedIcon />
                                </IconButton>
                            )
                        }
                    }
                ]}
                rowsPerPage={5}
                pageSizeOptions={[5, 10, 20]}
                check={false}
                page={0}
                // onCheck={console.log}
                onRowClick={(data, event) => {
                    if (['button','svg','path'].indexOf(event.target.localName) !== -1) return
                    setTable({
                        ...data.row, 
                        open: true
                    })
                }}
                disableRowSelectionOnClick={true}
                autoHeight={false}
                initialState = {{
                    columns: {
                        columnVisibilityModel: {
                            idtabelapreco: size.width > 500,
                            loja: true,
                            setor: true,
                            mostrar_preco_ecommerce: size.width > 500,
                            is_used: size.width > 500,
                            key_code: true,
                            codloja_int: false,
                            ip: false,
                            mac: false,
                            lat: false,
                            lng: false,
                            nomedispositivo: false,
                            Nome: false
                        }
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    }
                }}
                toolbar={true}
                sx={{
                    height:'100%'
                }}
                height={size.width <= 500 ? 'calc( 100vh - 160px )' : 'calc( 100vh - 140px )'}
                />
            </div>}
        </>
    )
})

export default Home